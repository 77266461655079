import React, { useEffect, useState } from 'react';
import { Button, CodeEditor } from '@grafana/ui';
import { CoreApp, QueryEditorProps } from '@grafana/data';
import { MongoDatasource } from '../data/MongoDatasource';
import { MongoDbJsonData, MongoDbQuery, QueryType } from '../types';
import { registerMongo } from './mongoEditor';
import { parseQuery } from './parser';
import { fixRunButton, fixEditButton } from '../utils';
import { styles } from '../styles';
import { selectors } from '../selectors';
import { validate } from './validate';

export type Props = QueryEditorProps<MongoDatasource, MongoDbQuery, MongoDbJsonData>;

interface Expand {
  height: string;
  icon: 'plus' | 'minus';
  on: boolean;
}

const defaultHeight = '150px';

export default function QueryEditor(props: Props) {
  const { datasource, onChange, onRunQuery, query, onBlur } = props;

  const [codeEditor, setCodeEditor] = useState<any>();
  const [expand, setExpand] = useState<Expand>({
    height: defaultHeight,
    icon: 'plus',
    on: (query as MongoDbQuery).expand || false,
  });

  useEffect(() => {
    fixRunButton();
    fixEditButton();
  }, []);


  const handleChange = (rawQuery: string) => {
    const parsedQuery = parseQuery(rawQuery, datasource, {});
    onChange({ ...query, query: rawQuery, queryType: QueryType.Query, parsedQuery: parsedQuery });
    onRunQuery();
  };

  const onToggleExpand = () => {
    const mongoQuery = query as MongoDbQuery;
    const on = !expand.on;
    const icon = on ? 'minus' : 'plus';
    onChange({ ...mongoQuery, expand: on });

    if (!codeEditor) {
      return;
    }
    if (on) {
      codeEditor.expanded = true;
      const height = getEditorHeight(codeEditor);
      setExpand({ height: `${height}px`, on, icon });
      return;
    }

    codeEditor.expanded = false;
    setExpand({ height: defaultHeight, icon, on });
  };

  const handleMount = (editor: any) => {
    const me = registerMongo(datasource);
    editor.expanded = (query as MongoDbQuery).expand;
    editor.onDidChangeModelDecorations((_: any) => {
      if (editor.expanded) {
        const height = getEditorHeight(editor);
        setExpand({ height: `${height}px`, on: true, icon: 'minus' });
      }
    });
    editor.onKeyUp((_: any) => {
      if (datasource.options.validate) {
        const sql = editor.getValue();
        validateQuery(sql, editor.getModel(), me, datasource);
      }
    });
    setCodeEditor(editor);
  };

  const run = () => handleChange(query.query);
  const isExplore = props.app === CoreApp.Explore;

  const onQueryBlur = (text: string) => {
    onChange({ ...query, query: text, queryType: QueryType.Query, parsedQuery: parseQuery(text, datasource, {}) })
    // Allows an extra onBlur handler to be passed in
    if (onBlur !== undefined) {
      onBlur()
    }
  };

  return (
    <>
      {!isExplore &&
        <div className={styles.QueryEditor.runButton}>
          <Button icon="play" variant="primary" size="sm" onClick={run}>
            Run query
          </Button>
        </div>
      }

      <div className={styles.Common.wrapper}>
        <a
          onClick={() => onToggleExpand()}
          className={styles.Common.expand}
          data-testid={selectors.components.QueryEditor.CodeEditor.Expand}
        >
          <i className={`fa fa-${expand.icon}`}></i>
        </a>
        <CodeEditor
          height={expand.height}
          language="mongo"
          value={query.query ?? ''}
          onSave={handleChange}
          showMiniMap={false}
          showLineNumbers={true}
          onEditorDidMount={handleMount}
          onBlur={onQueryBlur}
        />
      </div>
    </>
  );
}

const getEditorHeight = (editor: any): number | undefined => {
  const editorElement = editor.getDomNode();
  if (editorElement === undefined) {
    return undefined;
  }

  const lineCount = editor.getModel()?.getLineCount() || 1;
  return editor.getTopForLineNumber(lineCount + 1) + 40;
};

const validateQuery = (sql: string, model: any, me: any, datasource: MongoDatasource) => {
  const v = validate(sql, datasource);
  const errorSeverity = 8;
  if (v.valid) {
    me.setModelMarkers(model, 'mongo', []);
  } else {
    const err = v.error!;
    me.setModelMarkers(model, 'mongo', [
      {
        startLineNumber: err.startLine,
        startColumn: err.startCol,
        endLineNumber: err.endLine,
        endColumn: err.endCol,
        message: err.expected,
        severity: errorSeverity,
      },
    ]);
  }
};
