import { MongoDatasource } from '../data/MongoDatasource';
import { mongoSyntax } from './mongoTokenizer';

// TODO - webpack loader fails on css import
// import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
declare const monaco: any;

export function registerMongo(datasource: MongoDatasource) {
  const { languages } = monaco;

  const mongoLang = languages.getLanguages().find(l => l.id === 'mongo');
  if (mongoLang !== undefined) {
    return monaco.editor;
  }

  languages.register({ id: 'mongo' });

  languages.setMonarchTokensProvider('mongo', mongoSyntax);

  languages.registerCompletionItemProvider('mongo', {
    triggerCharacters: ['.', '('],
    provideCompletionItems: async (model, position) => {
      const textUntilPosition = model.getValueInRange({
        startLineNumber: 1,
        startColumn: 1,
        endLineNumber: position.lineNumber,
        endColumn: position.column,
      });

      // remove arguments within () eg. ({"foo": 1}), they may contain a .
      const clean = textUntilPosition.replace(/\s?\([^)]+\)/g, '()');
      // if we are within function args and using a . don't provide suggestions
      if (isWithinFunctionArgs(clean)) {
        return Promise.resolve({ suggestions: [] });
      }
      const parts: string[] = clean.split('.');
      if (parts.length === 1 && clean !== '') {
        return Promise.resolve({ suggestions: [] });
      }
      const word = model.getWordUntilPosition(position);
      const range = {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: word.startColumn,
        endColumn: word.endColumn,
      };
      if (textUntilPosition === '') {
        // fetch the databases
        const dbs = await datasource.fetchDatabases();
        const suggestions = dbs.map(db => ({
          label: db,
          kind: languages.CompletionItemKind.Keyword,
          documentation: 'Database',
          insertText: db,
          range: range,
        }));
        return { suggestions };
      } else {
        if (parts.length === 2) {
          // fetch collections
          const dbs = await datasource.fetchCollections(parts[0]);
          const suggestions = dbs.map(db => ({
            label: db,
            kind: languages.CompletionItemKind.Keyword,
            documentation: 'Database',
            insertText: db,
            range: range,
          }));
          return { suggestions };
        } else if (parts.length === 3 && !parts[1].includes('(')) {
          // suggest supported mongo functions
          const suggestions = [
            {
              label: 'find',
              kind: languages.CompletionItemKind.Function,
              documentation: 'Find results',
              range: range,
              insertText: 'find({"${1:filterField}":"${2:filterValue}"})',
              insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
            },
            {
              label: 'aggregate',
              kind: languages.CompletionItemKind.Function,
              documentation: 'Aggregate results',
              insertText: 'aggregate',
              range: range,
            },
          ];
          return Promise.resolve({ suggestions });
        } else if (parts.length === 4 || parts.length === 5) {
          // limit or sort
          const suggestions = [
            {
              label: 'limit',
              kind: languages.CompletionItemKind.Function,
              documentation: 'Limit results',
              range: range,
              insertText: 'limit(${1:number})',
              insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
            },
            {
              label: 'sort',
              kind: languages.CompletionItemKind.Function,
              documentation: 'Sort results',
              insertText: 'sort({"${1:field}": 1})',
              insertTextRules: languages.CompletionItemInsertTextRule.InsertAsSnippet,
              range: range,
            },
          ].filter(s => !parts[3].startsWith(s.label));
          return Promise.resolve({ suggestions });
        } else {
          return Promise.resolve({
            suggestions: [],
          });
        }
      }
    },
  });
  return monaco.editor;
}

function isWithinFunctionArgs(text: string) {
  let hasEndingParen = false;
  let hasStartingParen = false;
  for (let i = text.length; i > 0; i--) {
    if (text[i] === ')') {
      hasEndingParen = true;
    } else if (text[i] === '(') {
      hasStartingParen = true;
    }
    if (hasStartingParen && !hasEndingParen) {
      return true;
    }
    if (hasStartingParen && hasEndingParen) {
      return false;
    }
  }
  return false;
}
