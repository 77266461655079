import { SelectableValue } from '@grafana/data';
import { getTemplateSrv } from '@grafana/runtime';
import { isString, isEmpty, isArray } from 'lodash';
import { EnhancedScopedVars } from '../types';

interface DS {
  type: string;
  uid: string;
}

function isDS(ds: string | DS, name: string, uid: string) {
  return isString(ds) ? ds === name : ds?.uid === uid;
}

function isCompound(name: string) {
  return name.startsWith('_') && name.split('_').length > 2;
}

// allow a convention for using compound variables so we can filter on multiple values
// using a variable named _foo_bar will allow using $_foo and $_bar as variables in the query
// the query result compound value has to be foo-bar (use $concat in mongoDB aggregate query)
export function interpolateCompoundVariables(dsName: string, uid: string, query: string, scopedVars?: EnhancedScopedVars) {
  // the typing isn't right on getVariables

  const vars = getTemplateSrv().getVariables();
  const compoundVars: any[] = vars.filter((v: any) => isDS(v.datasource, dsName, uid) && isCompound(v.name));

  for (const v of compoundVars) {
    const selected = findSelected(v.options, v, scopedVars);
    if (shouldInterpolate(selected, scopedVars)) {
      const keys = v.name.replace('_', '').split('_');
      const values = selected?.text?.split('-');
      if (values?.length === keys.length) {
        keys.forEach((k: any, i: any) => {
          const regexp = new RegExp(`\\$_${k}`, 'g');
          query = query.replace(regexp, values[i].trim());
        });
      }
    }
  }
  return query;
}

function findSelected(opts: SelectableValue[], v: SelectableValue, scopedVars?: EnhancedScopedVars,): SelectableValue | undefined {
  if (scopedVars !== undefined) {
    const selected = findSelectedInScope(scopedVars);
    if (selected !== undefined) {
      return selected;
    }
  }
  const selected = opts.filter(o => o.selected);
  if (selected.length > 0) {
    return selected[0];
  }
  if (isArray(v.current?.value) && v.current?.value.length === 0) {
    return undefined;
  }
  return v.current;
}

function shouldInterpolate(selected?: SelectableValue, scopedVars?: EnhancedScopedVars): boolean {
  if (isEmpty(selected)) {
    return false;
  }
  if (scopedVars === undefined) {
    return true;
  }
  const found = findSelectedInScope(scopedVars);
  if (found && selected?.value === found.value) {
    return true;
  }
  return selected?.selected;
}

function findSelectedInScope(scopedVars: EnhancedScopedVars): SelectableValue | undefined {
  const values = Object.values(scopedVars);
  for (const val of values) {
    // TODO: Might need to change this logic now that ScopedVars no longer accept any key/value pair
    if (val.selected) {
      return val;
    }
  }
  return undefined;
}

export function getRawValue(val: string) {
  try {
    return parseInt(val, 10);
  } catch (e) {}
  try {
    return parseFloat(val);
  } catch (e) {}
  return val;
}
