import React, { useEffect} from 'react';
import { gte } from 'semver';
import { DataSourcePluginOptionsEditorProps, onUpdateDatasourceJsonDataOption, DataSourceSettings } from '@grafana/data';
import {
  ConfigSection,
  ConfigSubSection,
  Auth,
  AuthMethod,
  convertLegacyAuthProps,
  DataSourceDescription,
} from '@grafana/experimental';
import { Input, Switch, InlineField, useTheme2 } from '@grafana/ui';
import { config } from '@grafana/runtime';
import { css, cx } from '@emotion/css';
import { SecureSettings, MongoDbJsonData, UpdatedMongDbOptions } from '../types';

const MongoAuthMethods = [AuthMethod.NoAuth, AuthMethod.BasicAuth];

export type Props = DataSourcePluginOptionsEditorProps<MongoDbJsonData, SecureSettings>;

// parses provided options and updates old provisioning parameters to the new version
export const parseAndConvertOptions = (options: DataSourceSettings, jsonData: MongoDbJsonData) => {
  const updatedOptions: Partial<UpdatedMongDbOptions> = { jsonData: {} };

  if (!options.basicAuth && (jsonData.credentials || jsonData.user !== undefined || options.user !== undefined)) {
    updatedOptions.basicAuth = true;

    if (options.user !== undefined) {
      updatedOptions.basicAuthUser = options.user;
    } else if (jsonData.user !== undefined) {
      updatedOptions.basicAuthUser = jsonData.user;
    }
  }
  if (jsonData.skipTLSValidation) {
    updatedOptions.jsonData.tlsSkipVerify = true;
  }

  return updatedOptions;
};

export const ConfigEditor = (props: Props) => {
  const { spacing } = useTheme2();
  const { onOptionsChange, options } = props;
  const { jsonData, readOnly } = options;

  // https://regex101.com/r/K9yiqI/1
  const isValidUrl = /^(mongodb(\+srv)?:(?:\/{2})?)((\w+?):(\w+?)@|:?@?)(\S+?)(:(\d+)|(\w+:{0,1}\w*)?([\w\d.])+\/)(\/(\S+?))?(\S+)$/.test(
    jsonData.connection
  );

  // we want to use the properties as described in https://grafana.com/docs/grafana/latest/administration/provisioning/#datasources
  // but because we were not strictly enforcing using them previously we need a fallback for the customers
  useEffect(() => {
    const updatedOptions = parseAndConvertOptions(options, jsonData);

    onOptionsChange({
      ...options,
      ...updatedOptions,
      jsonData: {
        ...jsonData,
        ...updatedOptions.jsonData,
      },
    });
    // without that it would fall in an infinite recursive loop and we only want to run it once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const styles = {
    container: css({
      maxWidth: 578,
      marginBottom: spacing(2),
    }),
    toggleLabel: css`
      margin-bottom: 10px;
      margin-top: 15px;
      h5 {
        margin-bottom: 0;
        margin-right: 10px;
      }
    `,
    top0: css`
      margin-top: 0;
    `,
    checkbox: css`
      margin-bottom: 10px;
    `,
    connection: css`
      > div {
        width: 100%;
      }
    `,
    description: css`
      font-size: 12px;
    `,
    toggle: css`
      margin-top: 7px;
      margin-left: 5px;
    `,
    horizontalRuler: css`
      margin-top: 32px;
      margin-bottom: 32px;
    `,
  };
  
  const Divider = () => <hr className={styles.horizontalRuler} />;

  const convertedProps = convertLegacyAuthProps({
    config: props.options,
    onChange: onOptionsChange
  });
  const newAuthProps = {
    ...convertedProps,
    basicAuth: {
      ...convertedProps.basicAuth,
      userTooltip: 'The user is the username assigned to the MongoDB account.',
      passwordTooltip: 'The password is the password assigned to the MongoDB account.',
    }
  }

  const extendDefaultAuth = {
    [AuthMethod.BasicAuth]: {
      label: 'Credentials',
      description: 'Authenticate with default credentials assigned to the MongoDB account upon creation.'
    },
  }

  const onSwitchToggle = (key: keyof Pick<MongoDbJsonData, 'validate'>, value: boolean) => {
    onOptionsChange({
      ...options,
      jsonData: {
        ...options.jsonData,
        [key]: value,
      },
    });
  };

  return (
    <div className={styles.container}>
      <DataSourceDescription
        dataSourceName="MongoDB"
        docsLink="https://grafana.com/grafana/plugins/grafana-mongodb-datasource/"
        hasRequiredFields
      />

      <Divider />

      <ConfigSection title="Connection" className={cx(styles.container, styles.connection)}>
        <InlineField
          htmlFor="connection-url"
          label={'Connection string'}
          labelWidth={24}
          tooltip={
            <>
              A connection string contains the parameters required to connect to MongoDB.{' '}
              <a
                href="https://www.mongodb.com/docs/manual/reference/connection-string/"
                target="_blank"
                rel="noopener noreferrer"
              >
              View formatting details here.
              </a>
            </>
          }
          grow
          disabled={readOnly}
          invalid={!isValidUrl && !readOnly}
          required
          error={isValidUrl ? '' : 'Please enter a valid URL'}
          {...{ interactive: true }}
        >
          <Input
            id="connection-url"
            aria-label="Data source connection URL"
            onChange={onUpdateDatasourceJsonDataOption(props, 'connection')}
            value={jsonData.connection || ''}
            autoComplete="off"
            required
            placeholder={'mongodb+srv://cluster.host.net/dbname?retryWrites=true&w=majority'}
          />
        </InlineField>
      </ConfigSection>

      <Divider />

      <Auth
        {...newAuthProps}
        extendedDefaultOptions={extendDefaultAuth}
        readOnly={options.readOnly}
        visibleMethods={MongoAuthMethods}
        customHeaders={null}
      />

      <Divider />

      <ConfigSection
        title="Additional Settings"
        description="Additional settings are optional settings that can be configured for more control over your data source."
      >
        <ConfigSubSection title="Query Syntax Validation">
          <InlineField
            label="Enable syntax validation"
            tooltip="Enable real time query syntax validation. MongoDB BSON syntax will be validated as you type and show contextual errors."
            labelWidth={24}
            {...{ interactive: true }}
            >
            <div className={styles.toggle}>
            <Switch
              value={jsonData.validate}
              onChange={e => {
                onSwitchToggle('validate', e.currentTarget.checked);
              }}
            />
            </div>
          </InlineField>
        </ConfigSubSection>

        {config.featureToggles['secureSocksDSProxyEnabled'] && gte(config.buildInfo.version, '10.0.0') && (
          <ConfigSubSection title="Secure Socks Proxy">
            <InlineField
              labelWidth={24}
              label="Secure Socks Proxy"
              {...{ interactive: true }}
              tooltip={
                <>
                  Enable proxying the datasource connection through the secure socks proxy to a different network. See{' '}
                  <a
                    href="https://grafana.com/docs/grafana/next/setup-grafana/configure-grafana/proxy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Configure a datasource connection proxy
                  </a>
                  .
                </>
              }
            >
              <div className={styles.toggle}>
                <Switch
                  value={options.jsonData.enableSecureSocksProxy}
                  onChange={(e) => {
                    onOptionsChange({
                      ...options,
                      jsonData: { ...options.jsonData, enableSecureSocksProxy: e.currentTarget.checked },
                    });
                  }}
                />
              </div>
            </InlineField>
          </ConfigSubSection>
        )}

        <ConfigSubSection title="Backend Response Rows Limit">
          <InlineField label="Rows to Return" tooltip="Increasing this too much may lead to performance issues for larger queries">
            <Input type="number" value={options.jsonData.responseRowsLimit ?? 100000} onChange={(e) => {
              onOptionsChange({
                ...options,
                jsonData: { ...options.jsonData, responseRowsLimit: e.currentTarget.value },
              });
            }} step={1000} pattern="\d*" id='response-rows-limit' />
          </InlineField>
        </ConfigSubSection>
      </ConfigSection>
    </div>
  );
};
