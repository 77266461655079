import { rangeUtil } from "@grafana/data";
import { isString } from "lodash";

export function formatter(val, variable) {
    if (variable.type === 'interval' && variable.name.endsWith('_ms')) {
        return rangeUtil.intervalToMs(val);
    }
    if (variable.multi) {
        if (!Array.isArray(val)) {
            val = [val];
        }
        if (val.length > 0) {
            if (isString(val[0])) {
                return val.map(v => `"${v}"`).join(', ');
            }
            return val.join(', ');
        }
    }
    return val;
}
