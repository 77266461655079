import {parseFilter, parseProject} from 'mongodb-query-parser';
import { EJSON } from 'bson';
import { getTemplateSrv } from '@grafana/runtime';
import { MongoDatasource } from '../data/MongoDatasource';
import { interpolateCompoundVariables } from '../data/mongoInterpolate';
import { ScopedVars } from '@grafana/data';
import { formatter } from '../data/mongoFormat';

export const parseQuery = (rawQuery: string, ds: MongoDatasource, scopedVars: ScopedVars): string => {
  if (!rawQuery) {
    return null;
  }

  if (isDiagnostic(rawQuery)) {
    return rawQuery;
  }

  let query = { filters: '', projections: '' };
  let original = '';

  // remove carriage return characters
  rawQuery = rawQuery.replace(/[\r]+/g, '');

  if (rawQuery.indexOf('find') !== -1) {
    const splitQuery = rawQuery.split(').');

    if (!splitQuery.length) {
      return null;
    }

    const findQuery = (splitQuery[0] + ')').match(/\(\s*?\n*\{(.|\n)*\}\n*\s*?\)/);

    if (findQuery === null) {
      return rawQuery;
    }

    original = findQuery[0];
    query = getFiltersProjections(findQuery[0]);
  } else if (rawQuery.indexOf('aggregate') !== -1) {
    // validate the aggregate query - should contain parens and brackets
    const aggregateQuery = rawQuery.match(/\(\s*?\n*[\[|\ [](.|\n)*[\]| \]]\n*\s*?\)/);
    if (aggregateQuery === null) {
      console.error('invalid query:' + aggregateQuery);
      return rawQuery;
    }

    original = aggregateQuery[0];
    query.filters = aggregateQuery[0];
  }

  if (!query) {
    return rawQuery;
  }

  let formattedFilters = query.filters;

  if (formattedFilters.indexOf('"$__timeFrom"') !== -1 || formattedFilters.indexOf('"$__timeFrom"') !== -1) {
    return rawQuery;
  }

  formattedFilters = wrapMacros(formattedFilters);

  try {
    const cleanFilters = interpolate(formattedFilters, ds, scopedVars);
    const parsedFilters = parseFilter(cleanFilters);

    const cleanProjections = interpolate(query.projections, ds, scopedVars);
    const parsedProjections = parseProject(cleanProjections);

    let q = EJSON.stringify(parsedFilters);
    if (parsedProjections) {
      const p = EJSON.stringify(parsedProjections);
      q += `, ${p}`;
    }
    q = unWrapMacros(q);
    // double dollar signs are replaced with one
    // replace $$ with $$$$ so the last replace converts $$$$ back to $$
    q = q.replace(/\$\$/g, '$$$$$$$$')
    return rawQuery.replace(original, '(' + q + ')');
  } catch (e) {
    throw(e);
  }
};

function interpolate(formattedFilters: string, ds: MongoDatasource, scopedVars: ScopedVars): string {
  if (formattedFilters === "") {
    return formattedFilters;
  }
  const templateSrv = getTemplateSrv();
  let cleanFilters = formattedFilters;
  try {
    cleanFilters = templateSrv.replace(formattedFilters, scopedVars, formatter);
  } catch (e) {
    console.warn('error interpolating ', e);
  }
  try {
    cleanFilters = interpolateCompoundVariables(ds.name, ds.uid, cleanFilters, scopedVars);
  } catch (e) {
    console.warn('error interpolating ', e);
  }
  return cleanFilters;
}

function getFiltersProjections(query: string) {
  const items = getWithin(query, ['{', '}']);
  if (items.length === 0) {
    return { filters: '', projections: '' };
  }
  if (items.length === 1) {
    return { filters: items[0], projections: '' };
  }
  return { filters: items[0], projections: items[1] };
}

// get the items only within the outer characters.  Could be brackets, curly braces, etc.
function getWithin(v: string, b: string[]) {
  const result = [];
  const state = { current: '', found: false, depth: 0, index: 0 };
  for (let i = 0; i < v.length; i++) {
    if (v[i] === b[0]) {
      state.found = true;
      state.depth++;
    }
    if (state.found && state.depth > 0) {
      state.current += v[i];
    }
    if (v[i] === b[1]) {
      state.depth--;
    }
    if (state.depth === 0 && result[state.index] !== undefined) {
      result[state.index] = state.current;
      state.index++;
      state.found = false;
      state.current = '';
      continue;
    }
    if (state.found) {
      result[state.index] = state.current;
    }
  }
  return result;
}

const macros = ['$__timeFrom', '$__timeTo'];

// temporarily wrap macros with double quotes so it's valid json
function wrapMacros(filters: string) {
  for (const m of macros) {
    if (filters.indexOf(m) !== -1) {
      filters = replace(filters, m, `"${m}"`);
    }
  }
  return filters;
}

function unWrapMacros(q: string) {
  for (const m of macros) {
    q = replace(q, `"${m}"`, m);
  }
  return q;
}

function replace(s: string, f: string, t: string) {
  // @ts-ignore
  return s.replaceAll(f, t);
}

const DIAGNOSTICS = ['stats', 'serverStatus', 'replSetGetStatus', 'getLog', 'connPoolStats', 'connectionStatus', 'buildInfo', 'dbStats', 'hostInfo', 'lockInfo'];
function isDiagnostic(query: string) {
  return DIAGNOSTICS.find(diag => query.includes(diag) && !isQuery(query)) !== undefined;
}

const QUERIES = ['find', 'aggregate'];
function isQuery(query: string) {
  return QUERIES.find(q => query.includes(q)) !== undefined;
}
