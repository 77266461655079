import { DataQuery } from '@grafana/schema';
import { DataSourceJsonData, DataSourceSettings, ScopedVar, ScopedVars } from '@grafana/data';

type Modify<T, R> = Omit<T, keyof R> & R;

export interface MongoDbQuery extends DataQuery {
  filter?: {
    key: string;
    value: number | string;
  };
  query: string;
  parsedQuery?: any;
  database?: string;
  expand?: boolean;
}

interface EnhancedScopedVar extends ScopedVar {
  selected?: boolean;
}

export interface EnhancedScopedVars extends ScopedVars {
  [key: string]: EnhancedScopedVar | undefined;
}

export interface MongoDbJsonData extends DataSourceJsonData {
  connection: string;
  user: string;
  credentials: boolean;
  tlsAuth: boolean;
  tlsAuthWithCACert: boolean;
  serverName: string;
  skipTLSValidation: boolean;
  tlsSkipVerify: boolean;
  validate: boolean;
  enableSecureSocksProxy: boolean;
  responseRowsLimit: string;
}

// this might be overcomplicated but we need to set only some values on the jsonData and they're all required in MongoDbJsonData which I don't want to change
type MongDbOptions = DataSourceSettings<MongoDbJsonData>;
export type UpdatedMongDbOptions = Modify<MongDbOptions, { jsonData: Partial<MongoDbJsonData> }>

export interface SecureSettings {
  password?: string;
  basicAuthPassword?: string;
}

export enum QueryType {
  Query = 'query',
  Databases = 'databases',
  Collections = 'collections',
}
