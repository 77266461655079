import { ScopedVars } from "@grafana/data";
import { MongoDatasource } from "../data/MongoDatasource";
import { parseQuery } from "./parser";

export interface Error {
  startLine: number;
  endLine: number;
  startCol: number;
  endCol: number;
  message: string;
  expected: string;
}

export interface Validation {
  valid: boolean;
  error?: Error;
}

export interface ParseError {
  message: string;
  line: number;
  col: number;
}

// allow invalid keywords
const allow = [];

export function validate(rawQuery: string, ds: MongoDatasource, scopedVars?: ScopedVars): Validation {
  try {
    parseQuery(rawQuery, ds, scopedVars);
    return { valid: true };
  } catch (e: any) {
    const err = getError(e);
    const lines = rawQuery.split('\n');
    const line = lines[err.line-1];
    const bad = line.substring(0, err.col);
    if (allow.includes(bad.toUpperCase())) {
      return { valid: true };
    }

    if (line.trim() === bad) {
      // issue is on next line
      const nextLine = lines[err.line + 1];
      if (nextLine?.trim().startsWith('$')) {
        return { valid: true };
      }
    }

    const badSection = line.substring(err.col + 1);
    if (badSection.trim().startsWith('$')) {
      return { valid: true };
    }

    return {
      valid: false,
      error: {
        startLine: err.line,
        endLine: err.line,
        startCol: err.col + 1,
        endCol: err.col + 1,
        message: err.message,
        expected: err.message
      },
    };
  }
}

function getError(err: ParseError) {
  const parts = err.message.split('(');
  if (parts.length === 0) {
    return {message: 'unknown', col: 1, line: 1};
  }
  if (parts.length < 2) {
    return {message: parts[0], col: 1, line: 1};
  }
  const message = parts[0];
  let pos = parts[1] || '';
  pos = pos.replace(')', '');
  pos = pos.trim();
  const colLine = pos.split(':');
  const col = parseInt(colLine[1] || "1", 10);
  const line = parseInt(colLine[0] || "1", 10);
  return { message, col, line};
}
