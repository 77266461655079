import { MongoDatasource } from './data/MongoDatasource';
import { MongoDbQuery, MongoDbJsonData, SecureSettings } from './types';
import { DataSourcePlugin } from '@grafana/data';
import { ConfigEditor } from './components/ConfigEditor';
import MongoQueryEditor from './components/MongoQueryEditor';
import VariableQueryEditor from './components/VariableQueryEditor';

export const plugin = new DataSourcePlugin<MongoDatasource, MongoDbQuery, MongoDbJsonData, SecureSettings>(
  MongoDatasource
)
  .setConfigEditor(ConfigEditor)
  .setQueryEditor(MongoQueryEditor)
  .setVariableQueryEditor(VariableQueryEditor);
