import { DataSourceWithBackend, getTemplateSrv, TemplateSrv } from '@grafana/runtime';
import { MongoDbQuery, MongoDbJsonData, QueryType, EnhancedScopedVars } from '../types';
import { Observable } from 'rxjs';
import {
  DataSourceInstanceSettings,
  DataQueryRequest,
  DataQueryResponse,
  DataFrame,
  vectorator,
  ArrayDataFrame,
  TimeRange,
} from '@grafana/data';
import { interpolateCompoundVariables } from './mongoInterpolate';
import { parseQuery } from '../components/parser';
import { formatter } from './mongoFormat';

export interface Request {
  queryType: string;
  database?: string;
}

export class MongoDatasource extends DataSourceWithBackend<MongoDbQuery, MongoDbJsonData> {
  options: MongoDbJsonData;
  tagType?: string;
  templateSrv: TemplateSrv;

  // This enables default annotation support for 7.2+
  annotations = {};

  interpolate = true;
  adHocValues = {};

  constructor(instanceSettings: DataSourceInstanceSettings<MongoDbJsonData>) {
    super(instanceSettings);
    this.options = instanceSettings.jsonData;
    this.templateSrv = getTemplateSrv();
  }

  query(request: DataQueryRequest<MongoDbQuery>): Observable<DataQueryResponse> {
    return super.query(this.validate(request, request.scopedVars));
  }

  filterQuery(query: MongoDbQuery): boolean {
    return !query.hide;
  }

  validate(request: DataQueryRequest<MongoDbQuery>, scopedVars: EnhancedScopedVars): DataQueryRequest<MongoDbQuery> {
    const targets = request.targets.map(query => ({
      ...query,
      queryType: this.getQueryType(query.queryType),
      parsedQuery: parseQuery(query.query, this, scopedVars),
    }));
    return { ...request, targets };
  }

  getQueryType(queryType: string): string {
    const qt = Object.keys(QueryType).find(x => QueryType[x] === queryType);
    return qt !== undefined ? QueryType[qt] : QueryType.Query;
  }

  async metricFindQuery(query: Partial<MongoDbQuery>, options?: { range: any }) {
    const frame = await this.runQuery(query, options?.range);
    if (frame.fields?.length === 0) {
      return [];
    }
    if (frame?.fields?.length === 1) {
      return vectorator(frame?.fields[0]?.values).map(text => ({ text, value: text }));
    }
    // convention - assume the first field is an id field
    const ids = frame?.fields[0]?.values;
    return vectorator(frame?.fields[1]?.values).map((text, i) => ({ text, value: ids.get(i) }));
  }

  applyTemplateVariables(query: MongoDbQuery, scopedVars: EnhancedScopedVars) {
    if (!query.query || query.hide) {
      return query; // Don't do anything
    }

    let mongoQuery = interpolateCompoundVariables(this.name, this.uid, query.query, scopedVars);
    let parsedQuery = interpolateCompoundVariables(this.name, this.uid, query.parsedQuery, scopedVars);
    const adHocFilters = array(this.templateSrv as any)
      .getAdhocFilters(this.name)
      .map(f => ({ key: f.key, value: this.adHocValues[f.value] })) || [undefined];

    try {
      mongoQuery = this.templateSrv.replace(mongoQuery, scopedVars, formatter);
      parsedQuery = this.templateSrv.replace(parsedQuery, scopedVars, formatter);
    } catch (err) {
      console.warn('Failed to inject template variables');
    }

    return {
      ...query,
      filter: adHocFilters[0],
      query: mongoQuery,
      parsedQuery: parsedQuery,
    };
  }

  async getTagKeys() {
    const frame = await this.fetchTags();
    return frame.fields.map(f => ({ text: f.name }));
  }

  async getTagValues({ key }) {
    const frame = await this.fetchTags();
    const field = frame.fields.find(f => f.name === key);
    if (field) {
      // Convert to string to avoid https://github.com/grafana/grafana/issues/28338
      return vectorator(field.values)
        .filter(value => value !== null)
        .map(value => {
          const text = String(value);
          this.adHocValues[text] = value;
          return { text };
        });
    }
    return [];
  }

  onSave() {
    console.log('on save');
  }

  async fetchDatabases(): Promise<string[]> {
    const frame = await this.runQuery({ queryType: QueryType.Databases });
    return frame?.fields[0]?.values.toArray() ?? [];
  }

  async fetchCollections(database: string): Promise<string[]> {
    const frame = await this.runQuery({ queryType: QueryType.Collections, database });
    return frame?.fields[0]?.values.toArray() ?? [];
  }

  async fetchTags(): Promise<DataFrame> {
    // @todo https://github.com/grafana/grafana/issues/13109
    const query = this.templateSrv.replace('$mongo_adhoc_query');
    if (query === '$mongo_adhoc_query') {
      return new ArrayDataFrame([]);
    } else {
      return await this.runQuery({ query, queryType: QueryType.Query });
    }
  }

  runQuery(request: Partial<MongoDbQuery>, range?: TimeRange): Promise<DataFrame> {
    return new Promise(resolve => {
      let req = {
        targets: [{ ...request, refId: String(Math.random()) }],
      } as DataQueryRequest<MongoDbQuery>;
      if (range) {
        req.range = range;
      }
      this.query(req).subscribe(res => {
        resolve(res.data[0] || { fields: [] });
      });
    });
  }
}

export function array(val: any) {
  return val ?? [];
}
