export function fixRunButton() {
    // workaround for e2e selector wrong in core grafana e2e workflow
    const legacyRefresh = document.querySelector('[aria-label="RefreshPicker run button"]');
    if (!legacyRefresh) {
        const e2eButton = document.createElement('div');
        e2eButton.setAttribute('aria-label', 'RefreshPicker run button');
        e2eButton.style.height = '1px';
        e2eButton.style.width = '1px';
        e2eButton.style.position = 'fixed';
        e2eButton.style.top = '0px';
        e2eButton.style.left = '100px';
        e2eButton.style.zIndex = '5000';
        document.body.appendChild(e2eButton);
    }
}
export function fixEditButton() {
    // workaround for e2e selector wrong in core grafana e2e workflow
    const legacyRefresh = document.querySelector('[aria-label="Panel header item Edit"]');
    if (!legacyRefresh) {
        const e2eButton = document.createElement('div');
        e2eButton.setAttribute('aria-label', 'Panel header item Edit');
        e2eButton.style.height = '1px';
        e2eButton.style.width = '1px';
        e2eButton.style.position = 'fixed';
        e2eButton.style.top = '10px';
        e2eButton.style.left = '100px';
        e2eButton.style.zIndex = '5000';
        document.body.appendChild(e2eButton);
    }
}
