import React, { useState } from 'react';
import { MongoDatasource } from '../data/MongoDatasource';
import { MongoDbQuery } from '../types';
import MongoQueryEditor from './MongoQueryEditor';
import { CoreApp } from '@grafana/data';

export interface Props {
  datasource: MongoDatasource;
  onChange: (query: MongoDbQuery, definition: string) => void;
  query: MongoDbQuery;
  app: CoreApp;
}

export default function VariableQueryEditor(props: Props) {
  const { datasource, onChange, app } = props;
  const [query, setQuery] = useState(props.query);

  const handleChange = (newQuery: MongoDbQuery) => {
    setQuery(newQuery);
  };

  const onRun = () => {
    onChange(query, `Query: ${query.query}`);
  };

  const onBlur = () => {
    if (query.query !== props.query.query) {
      // workaround for "Run query" button at the bottom of the page
      // force the model update.  this will also trigger the query to run
      // but without this the model will be out of sync when clicking "Run query"
      onChange(query, `Query: ${query.query}`);
    }
  };

  return (
    <MongoQueryEditor
      datasource={datasource}
      onChange={handleChange}
      onRunQuery={onRun}
      onBlur={onBlur}
      query={query}
      app={app}
    />
  );
}
